import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";
import { saveAs } from "file-saver";

const useUsers = (props) => {
  const { data, isLoading, refetch } = useQuery(
    ["GET_USERS", props?.page, props?.amount],
    () =>
      request.get("users", {
        params: {
          page: props.page,
          limit: props.amount,
        },
      }),
    {
      enabled: props?.enabled,
      refetchOnWindowFocus: false,
    }
  );

  const { data: user } = useQuery(
    ["GET_USER", props?.userId],
    () => request.get("users/" + props?.userId),
    {
      enabled: !!props?.userId,
    }
  );

  const createUser = useMutation((data) => request.post("users", data));

  const updateUser = useMutation((data) =>
    request.put("users/" + data.id, data)
  );

  const deleteUser = useMutation((id) => request.delete("users/" + id));

  const downloadContacts = useMutation(async () => {
    const response = await request.get("/customer/report", {
      responseType: "blob",
    });

    saveAs(response, "contacts.xlsx");
    return "success";
  });

  return {
    data,
    user,
    isLoading,
    refetch,
    createUser,
    updateUser,
    deleteUser,
    downloadContacts,
  };
};

export default useUsers;
