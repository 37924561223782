import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useOrderAPI = ({
  page,
  limit,
  isGetOrders = false,
  orderId,
  user_ids,
  start_date,
  end_date,
  externalOrderId,
  passengerSearch,
  status,
}) => {
  const { data, isLoading, refetch } = useQuery(
    [
      "GET_ORDERS",
      page,
      limit,
      user_ids,
      start_date,
      end_date,
      externalOrderId,
      passengerSearch,
      status,
    ],
    () =>
      request.get("order", {
        params: {
          page,
          limit,
          user_ids,
          start_date,
          end_date,
          external_order_id: externalOrderId,
          search: passengerSearch,
          status,
        },
      }),
    {
      enabled: !!isGetOrders,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: order,
    isLoading: isLoadingCurrentOrder,
    refetch: refetchCurrentOrder,
  } = useQuery(["GET_ORDER", orderId], () => request.get("order/" + orderId), {
    enabled: !!orderId,
  });

  const createOrder = useMutation((data) => request.post("order", data));
  const createExchangeOrder = useMutation((data) =>
    request.post("order/exchange", data)
  );
  const orderAssign = useMutation((data) =>
    request.post("order/assign", data, { params: { order_id: data.id } })
  );
  const updateOrder = useMutation((data) =>
    request.put("order/" + data.id, data)
  );
  const confirmOrder = useMutation((data) =>
    request.put("order/confirm/" + data.id, data)
  );
  const deleteOrder = useMutation((id) => request.delete("order/" + id));

  return {
    createOrder,
    updateOrder,
    createExchangeOrder,
    data,
    isLoading,
    refetch,
    deleteOrder,
    order: order?.order,
    isLoadingCurrentOrder,
    refetchCurrentOrder,
    confirmOrder,
    orderAssign,
  };
};

export default useOrderAPI;
