import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Box, Button, TextField } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TSelect from "../../components/Select";
import TTable from "../../components/Table";
import Wrapper from "../../components/Wrapper";
import useOrderAPI from "../../hooks/api/useOrderAPI";
import useUsers from "../../hooks/api/useUsersAPI";
import usePagination from "../../hooks/usePagination";
import { isUserAdmin, isUserSupervisor } from "../../utils";
import { getAgentsByName } from "../../utils/getAgents";
import orderColumns, { FlightColumn, OrderActionRenderer } from "./columns";
import moment from "moment";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import OrderPopup from "./OrderPopup";
import { useDebounce } from "use-debounce";
import styles from "./style.module.scss";

const Orders = () => {
  const navigate = useNavigate();
  const selectInputRef = useRef();
  const [selectedOrder, setSelectedOrder] = useState();
  const [leadID, setLeadID] = useState(null);
  const [searchPassengers, setSearchPassengers] = useState(null);
  const [debouncedSearchPassenger] = useDebounce(searchPassengers, 500);
  const [debouncedSearchLeadID] = useDebounce(leadID, 500);
  const { user } = useSelector((store) => store.auth);
  const { status } = useSelector((store) => store.order);

  const { amount, page, handleAmount, handlePaginate, filters } =
    usePagination("/orders");

  const { data: users } = useUsers({
    enabled: true,
    page: 0,
    amount: 20,
  });

  const { data, isLoading, refetch } = useOrderAPI({
    isGetOrders: true,
    page: page - 1,
    limit: amount || 20,
    status: status,
    user_ids:
      isUserAdmin(user.role) || isUserSupervisor(user.role)
        ? filters?.agents?.split("%2C")?.join(",")
        : null,
    start_date: filters.start_date,
    end_date: filters.end_date,
    externalOrderId: debouncedSearchLeadID,
    passengerSearch: debouncedSearchPassenger,
  });

  const onChangeAgentsFilter = (e) => {
    const search = {
      page: page,
      amount,
      agents: e.map((ag) => ag.id).join(","),
      start_date: filters.start_date,
      end_date: filters.end_date,
    };

    if (!filters.start_date) delete search.start_date;
    if (!filters.end_date) delete search.end_date;
    if (e.length === 0) delete search.agents;

    navigate({
      pathname: "/orders",
      search: createSearchParams(search).toString(),
    });
  };

  const onChangeDateFilter = (e) => {
    const search = {
      page: page,
      amount,
      agents: filters.agents,
      start_date: moment(e?.[0]).format("YYYY-MM-DD"),
      end_date: moment(e?.[1]).format("YYYY-MM-DD"),
    };

    if (!filters.agents) delete search.agents;

    navigate({
      pathname: "/orders",
      search: createSearchParams(search).toString(),
    });
  };

  const onCleanDate = () => {
    const search = {
      page: page,
      amount,
      agents: filters.agents,
    };

    if (!filters.agents) delete search.agents;

    navigate({
      pathname: "/orders",
      search: createSearchParams(search).toString(),
    });
  };

  const onClearFilter = () => {
    selectInputRef?.current?.clearValue();
    onCleanDate();
    setLeadID("");
    setSearchPassengers("");
  };

  const mutatedColumns = useMemo(() => {
    return [
      {
        label: "Flights",
        key: "flights",
        attributes: {
          width: 220,

          padding: "5px 10px",
        },
        render: ({ data }) => (
          <FlightColumn data={data} onClick={setSelectedOrder} />
        ),
      },
      ...orderColumns,
      {
        label: "Actions",
        key: "actions",
        attributes: {
          width: 60,
        },
        render: ({ data }) => (
          <OrderActionRenderer data={data} refetch={refetch} />
        ),
      },
    ];
  }, [orderColumns]);

  return (
    <Wrapper>
      <div>
        <TTable
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          total={data?.orders?.count}
          data={data?.orders?.rows}
          columns={mutatedColumns}
          isLoading={isLoading}
        >
          <Box
            display="flex"
            gap="8px"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" alignItems="center" gap="10px">
              {(isUserAdmin(user.role) || isUserSupervisor(user.role)) && (
                <TSelect
                  ref={selectInputRef}
                  isMulti={true}
                  components={{
                    DropdownIndicator: PersonRoundedIcon,
                    IndicatorSeparator: null,
                  }}
                  // value={filterAgents}
                  loadOptions={getAgentsByName}
                  defaultOptions={users?.users?.rows}
                  getOptionLabel={(opt) =>
                    [opt?.first_name, opt?.last_name].join(" ")
                  }
                  onChange={onChangeAgentsFilter}
                  getOptionValue={(opt) => opt.id}
                  placeholder="Select agents"
                />
              )}
              <DateRangePicker
                placeholder="Select dates"
                onClean={onCleanDate}
                onChange={onChangeDateFilter}
                placement="auto"
                preventOverflow
                className="filter_date_picker"
              />
              <TextField
                className={styles.text_field}
                placeholder="Search passenger"
                name="search"
                value={searchPassengers}
                onChange={(e) => setSearchPassengers(e.target.value)}
              />
              <TextField
                className={styles.text_field}
                type="number"
                placeholder="Enter Lead ID"
                name="external_order_id"
                value={leadID}
                onChange={(e) => setLeadID(e.target.value)}
              />
              {(!!filters.agents || !!searchPassengers || !!leadID) && (
                <Button
                  variant="contained"
                  onClick={() => {
                    onClearFilter();
                    navigate("/orders");
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100px",
                    fontWeight: 500,
                  }}
                >
                  Clear
                </Button>
              )}
            </Box>
          </Box>
        </TTable>
      </div>

      <OrderPopup
        isOpen={!!selectedOrder}
        close={() => setSelectedOrder(null)}
        data={selectedOrder}
      />
    </Wrapper>
  );
};

export default Orders;
