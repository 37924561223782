import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import authSlice from "./auth/authSlice";
import orderSlice from "./order/orderSlice";
import settingsSlice from "./settings/settingsSlice";
import segmentSlice from "./segment/segmentSlice";

const authPersistConfig = {
  key: "auth",
  storage,
};

const orderPersistConfig = {
  key: "order",
  storage,
};

const segmentPersistConfig = {
  key: "segment",
  storage,
};

const settingsPersistConfig = {
  key: "settings",
  storage,
};

const authPersistedReducer = persistReducer(authPersistConfig, authSlice);

const orderPersistedReducer = persistReducer(orderPersistConfig, orderSlice);

const segmentPersistedReducer = persistReducer(
  segmentPersistConfig,
  segmentSlice
);

const settingsPersistedReducer = persistReducer(
  settingsPersistConfig,
  settingsSlice
);

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer,
    order: orderPersistedReducer,
    settings: settingsPersistedReducer,
    segment: segmentPersistedReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);
