import { Box, Button, TextField, Typography } from "@mui/material";
import useAmadeus from "../../../hooks/useAmadeus";
import { useDispatch } from "react-redux";
import {
  setAmadeusToken,
  setToken,
  setUser
} from "../../../redux/auth/authSlice";
import { useCallback, useState } from "react";
import useAuth from "../../../hooks/api/useAuth";
import { toast } from "react-hot-toast";
import { setVisitedTime } from "../../../redux/settings/settingsSlice";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const { authorize } = useAmadeus();
  const { login } = useAuth();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      if (!email) return toast.error("Enter email!");
      if (!password) return toast.error("Enter password!");

      login.mutate(
        { email, password },
        {
          onSuccess: (res) => {
            authorize.mutate(null, {
              onSuccess: (res) => {
                dispatch(setAmadeusToken(res?.access_token));
              },
              onError: (err) => {
                console.log(err);
              }
            });
            toast.success(
              "Welcome, " + [res.first_name, res.last_name].join(" ")
            );
            dispatch(setVisitedTime());
            dispatch(setUser(res));
            dispatch(setToken(res.token));
          },
          onError: (err) => {
            toast.error("Непарвильные данные");
          }
        }
      );
    },
    [email, password]
  );

  return (
    <Box
      width="100%"
      height="100vh"
      bgcolor="rgba(248, 248, 248, 1)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width="463px"
        p="24px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor="white"
      >
        <img
          src={require("../../../assets/icons/logo.png")}
          alt="logo"
          width={154}
        />
        <Typography fontSize="20px" fontWeight={600} mt="16px">
          Welcome
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight={400}
          mt="12px"
          color="rgba(95, 95, 95, 1)"
        >
          Login to your account
        </Typography>
        <form onSubmit={onClick} style={{ width: "100%" }}>
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            mt="24px"
            gap="16px"
          >
            <Box display="flex" flexDirection="column">
              <Typography>Email</Typography>
              <TextField
                placeholder="tripoasia@example.com"
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  backgroundColor: "#fff"
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography>Password</Typography>
              <TextField
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  backgroundColor: "#fff"
                }}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: "24px" }}
            type="submit"
          >
            Login
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
