import { forwardRef } from "react";
import AsyncSelect from "react-select/async";
import { selectStyles } from "./styles";

const TSelect = forwardRef(({ loadOptions, ...props }, ref) => {
  return (
    <AsyncSelect
      ref={ref}
      loadOptions={loadOptions}
      components={{ IndicatorSeparator: null }}
      {...props}
      styles={{
        ...selectStyles,
        ...props.styles,
        control: () => ({
          ...selectStyles.control(),
          ...props?.styles?.control(),
        }),
      }}
    />
  );
});

export default TSelect;
