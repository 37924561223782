import Layout from "../layouts/Layout/Layout";
import Login from "../pages/Auth/Login";

import { Navigate, createBrowserRouter } from "react-router-dom";
import { _adminRoutes, _agentRoutes, _editorRoutes } from "./routes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [..._adminRoutes]
  }
]);

export const agentRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [..._agentRoutes]
  }
]);

export const editorRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [..._editorRoutes]
  }
]);

export const authRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
]);
