export const ClockIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_419_3105)">
      <path
        d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_419_3105">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default function ArrowLeft() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12H5M5 12L12 19M5 12L12 5"
        stroke="#344054"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function NotFoundPageIcon() {
  return (
    <svg
      width="480"
      height="350"
      viewBox="0 0 480 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="240" cy="175" r="174.545" fill="#FECDCA" />
      <circle cx="56.7273" cy="44.091" r="17.4545" fill="#FEE4E2" />
      <circle cx="432" cy="275.364" r="13.0909" fill="#FEE4E2" />
      <circle cx="54.5454" cy="301.545" r="21.8182" fill="#FEE4E2" />
      <circle cx="458.182" cy="100.818" r="21.8182" fill="#FEE4E2" />
      <circle cx="416.727" cy="24.4546" r="15.2727" fill="#FEE4E2" />
      <g filter="url(#filter0_dd_938_25102)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M247.605 35.3638C211.09 35.3638 178.789 53.4077 159.132 81.0656C152.713 79.5518 146.019 78.7508 139.138 78.7508C91.2137 78.7508 52.3636 117.601 52.3636 165.525C52.3636 213.449 91.2137 252.299 139.138 252.299L139.211 252.299H355.954C355.993 252.299 356.033 252.299 356.073 252.299C398.006 252.299 432 218.305 432 176.372C432 134.438 398.006 100.444 356.073 100.444C353.094 100.444 350.156 100.616 347.267 100.95C330.645 62.3712 292.279 35.3638 247.605 35.3638Z"
          fill="#FEF3F2"
        />
        <circle
          cx="139.138"
          cy="165.525"
          r="86.774"
          fill="url(#paint0_linear_938_25102)"
        />
        <circle
          cx="247.605"
          cy="143.831"
          r="108.468"
          fill="url(#paint1_linear_938_25102)"
        />
        <circle
          cx="356.073"
          cy="176.372"
          r="75.9273"
          fill="url(#paint2_linear_938_25102)"
        />
      </g>
      <g filter="url(#filter1_b_938_25102)">
        <rect
          x="178.909"
          y="183.727"
          width="122.182"
          height="122.182"
          rx="61.0909"
          fill="#D92D20"
          fill-opacity="0.8"
        />
        <path
          d="M262.909 267.727L254 258.818M260.364 243.546C260.364 255.495 250.677 265.182 238.727 265.182C226.778 265.182 217.091 255.495 217.091 243.546C217.091 231.596 226.778 221.909 238.727 221.909C250.677 221.909 260.364 231.596 260.364 243.546Z"
          stroke="white"
          stroke-width="4.36364"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_938_25102"
          x="32.3636"
          y="35.3638"
          width="419.636"
          height="256.935"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_938_25102"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_938_25102"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_938_25102"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_938_25102"
            result="effect2_dropShadow_938_25102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_938_25102"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_938_25102"
          x="170.909"
          y="175.727"
          width="138.182"
          height="138.182"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_938_25102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_938_25102"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_938_25102"
          x1="72.5076"
          y1="108.192"
          x2="225.912"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_938_25102"
          x1="164.318"
          y1="72.1652"
          x2="356.073"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_938_25102"
          x1="297.771"
          y1="126.205"
          x2="432"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export const NotePadHeaderIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_582_9240)">
      <rect x="2" y="1" width="32" height="32" rx="6" fill="white" />
      <rect x="2.5" y="1.5" width="31" height="31" rx="5.5" stroke="#EAECF0" />
      <path
        d="M17.3333 11.6667H15.2C14.0799 11.6667 13.5198 11.6667 13.092 11.8847C12.7157 12.0764 12.4097 12.3824 12.218 12.7587C12 13.1865 12 13.7466 12 14.8667V19.8C12 20.9201 12 21.4802 12.218 21.908C12.4097 22.2843 12.7157 22.5903 13.092 22.782C13.5198 23 14.0799 23 15.2 23H20.1333C21.2534 23 21.8135 23 22.2413 22.782C22.6176 22.5903 22.9236 22.2843 23.1153 21.908C23.3333 21.4802 23.3333 20.9201 23.3333 19.8V17.6667M18.6667 20.3333H14.6667M20 17.6667H14.6667M23.4142 11.5858C24.1953 12.3668 24.1953 13.6332 23.4142 14.4142C22.6332 15.1953 21.3668 15.1953 20.5858 14.4142C19.8047 13.6332 19.8047 12.3668 20.5858 11.5858C21.3668 10.8047 22.6332 10.8047 23.4142 11.5858Z"
        stroke="#344054"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_582_9240"
        x="0"
        y="0"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_582_9240"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_582_9240"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const DotIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="4" fill="#12B76A" />
  </svg>
);
