export const isurancePercentage = 9;

export const flightTypes = {
  ONE_WAY: "one_way",
  ROUND_TRIP: "round_trip",
  DIFFICULT_ROUTE: "difficult_route",
};

export const flightClasses = {
  BUSINESS: "business",
  ECONOM: "econom",
};

export const flightClassesArray = [
  {
    key: flightClasses.BUSINESS,
    value: "Бизнес клас",
  },
  {
    key: flightClasses.ECONOM,
    value: "Эконом клас",
  },
];

export const sources = [
  {
    label: "Instagram",
    value: "INSTAGRAM",
  },
  {
    label: "Telegram",
    value: "TELEGRAM",
  },
  {
    label: "Whatsapp",
    value: "WHATSAPP",
  },
  {
    label: "Facebook",
    value: "FACEBOOK",
  },
  {
    label: "Website",
    value: "WEBSITE",
  },
  {
    label: "Friend",
    value: "FRIEND",
  },
];

export const flightTypesArray = [
  {
    key: flightTypes.ONE_WAY,
    value: "One Way",
  },
  {
    key: flightTypes.ROUND_TRIP,
    value: "Round trip",
  },
  {
    key: flightTypes.DIFFICULT_ROUTE,
    value: "Multiple flights",
  },
];
export const flightTypesOption = [
  {
    value: flightTypes.ONE_WAY,
    label: "One Way",
  },
  {
    value: flightTypes.ROUND_TRIP,
    label: "Round trip",
  },
  {
    value: flightTypes.DIFFICULT_ROUTE,
    label: "Multiple flights",
  },
];

export const iataCodes = [
  {
    value: "CVG",
    key: "Cincinatti - CVG",
  },
  {
    value: "CLE",
    key: "Cleveland - CLE",
  },
  {
    value: "CMH",
    key: "Columbus - CMH",
  },
  {
    value: "RSW",
    key: "Ford Mayers - RSW",
  },
  {
    value: "MCO",
    key: "Orlando - MCO",
  },
  {
    value: "ATL",
    key: "Atalanta - ATL",
  },
  {
    value: "IAH",
    key: "Houston - IAH",
  },
  {
    value: "DFW",
    key: "Dallas - DFW",
  },
  {
    value: "BOS",
    key: "Boston - BOS",
  },
  {
    value: "BWI",
    key: "Baltimore - BWI",
  },
  {
    value: "ORD",
    key: "Chicago - ORD",
  },
  {
    value: "TAS",
    key: "Tashkent - TAS",
  },
  {
    value: "IST",
    key: "Istanbul - IST",
  },
  {
    value: "IAD",
    key: "Washington DC - IAD",
  },
  {
    value: "NYC",
    key: "New York - JFK / NYC",
  },
  {
    value: "EWR",
    key: "New York - EWR",
  },
  {
    value: "DYU",
    key: "Dyushanbe - DYU",
  },
  {
    value: "SKD",
    key: "Samarkand - SKD",
  },
  {
    value: "BHK",
    key: "Bukhara - BHK",
  },
  {
    value: "UGC",
    key: "Urgench - UGC",
  },
  {
    value: "NMA",
    key: "Namangan - NMA",
  },
  {
    value: "FEG",
    key: "Ferghana - FEG",
  },
  {
    value: "AZN",
    key: "Andijan - AZN",
  },
  {
    value: "LED",
    key: "Sankt Piterburg - LED",
  },
  {
    value: "DME",
    key: "Moscow (MOW) - DME",
  },
  {
    value: "VKO",
    key: "Moscow (MOW) - VKO",
  },
  {
    value: "ZIA",
    key: "Moscow (MOW) - ZIA",
  },
  {
    value: "SVO",
    key: "Moscow (MOW) - SVO",
  },
  {
    value: "DXB",
    key: "Dubai - DXB",
  },
  {
    value: "DWC",
    key: "Dubai - DWC",
  },
  {
    value: "HSA",
    key: "TURKISTAN - HSA",
  },
  {
    value: "BNA",
    key: "Nashville Tennese - BNA",
  },
  {
    value: "CAI",
    key: "Cairo - CAI",
  },
  {
    value: "GYD",
    key: "Heydar Aliyev In.Airport - GYD",
  },
  {
    value: "DTW",
    key: "Detroit Metropolitan Wayne County Airport - DTW",
  },
  {
    value: "BDL",
    key: "Bradley International Airport - BDL",
  },
  {
    value: "PEK",
    key: "Beijing - PEK",
  },
  {
    value: "LGA",
    key: "La Guardia - LGA",
  },
  {
    value: "OMS",
    key: "Omsk - OMS",
  },
  {
    value: "YYZ",
    key: "Toronto - YYZ",
  },
  {
    value: "ICN",
    key: "Seoul - ICN",
  },
  {
    value: "PHL",
    key: "Philadelphia - PHL",
  },
  {
    value: "PIT",
    key: "Pittsburgh - PIT",
  },
  {
    value: "SFO",
    key: "San Francisco - SFO",
  },
  {
    value: "LAX",
    key: "Los Angeles - LAX",
  },
  {
    value: "LCY",
    key: "London City Airport - LCY",
  },
  {
    value: "LHR",
    key: "London Heatrow Airport - LHR",
  },
  {
    value: "AMS",
    key: "Amsterdam - AMS",
  },
  {
    value: "ASB",
    key: "Ashqabat - ASB",
  },
  {
    value: "SSH",
    key: "Sharm El Sheikh - SSH",
  },
  {
    value: "SGN",
    key: "Ho Chi  Minh City - SGN",
  },
  {
    value: "CXR",
    key: "Cam Ranh - CXR",
  },
  {
    value: "MDW",
    key: "Midwey Airport - MDW",
  },
];

export const paymentTypes = {
  PENDING: "pending",
  PAID: "paid",
};

export const paymentTypesArray = [
  {
    key: paymentTypes.PENDING,
    value: "Pending",
  },
  {
    key: paymentTypes.PAID,
    value: "Paid",
  },
];

export const weightTypes = {
  PC: "pc",
  KG: "kg",
  NO: "no",
};

export const weightTypesArray = [
  {
    key: weightTypes.NO,
    value: "NO",
  },
  {
    key: weightTypes.KG,
    value: "KG",
  },
  {
    key: weightTypes.PC,
    value: "PC",
  },
];

export const carrierCodes = [
  {
    key: "HY",
    value: "HY",
  },
  {
    key: "TK",
    value: "TK",
  },
  {
    key: "UA",
    value: "UA",
  },
  {
    key: "OZ",
    value: "OZ",
  },
  {
    key: "EK",
    value: "EK",
  },
  {
    key: "B9",
    value: "B9",
  },
  {
    key: "LO",
    value: "LO",
  },
  {
    key: "DP",
    value: "DP",
  },
  {
    key: "NK",
    value: "NK",
  },
  {
    key: "MS",
    value: "MS",
  },
  {
    key: "HH",
    value: "HH",
  },
  {
    key: "AA",
    value: "AA",
  },
  {
    key: "DL",
    value: "DL",
  },
  {
    key: "KL",
    value: "KL",
  },
  {
    key: "4S",
    value: "4S",
  },
  {
    key: "AI",
    value: "AI",
  },
  {
    key: "WN",
    value: "WN",
  },
  {
    key: "9S",
    value: "9S",
  },
];
