import { Box, Button, TextField, TextareaAutosize } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import {
  publishStatusKeys,
  publishStatuses,
  siteTypes,
} from "../../../../consts/content";

import styles from "./style.module.scss";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import TSelect from "../../../../components/Select";
import useCategoryAPI from "../../../../hooks/api/useCategoryAPI";

const CategoryCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { category, createCategory, updateCategory } = useCategoryAPI({
    categoryId: id,
  });

  const { control, handleSubmit } = useForm({
    values: { ...(category?.category || {}) },
  });

  const [status, setStatus] = useState(null);
  const [site, setSite] = useState(null);

  useEffect(() => {
    if (!category) return;

    setStatus(
      category?.category?.is_published ? publishStatuses[0] : publishStatuses[1]
    );

    setSite(
      category?.category?.site
        ? siteTypes.find((site) => category?.category?.site === site.key)
        : siteTypes[0]
    );
  }, [category]);

  console.log("categories page");

  const onClick = useCallback(
    (vals) => {
      if (!!id) {
        updateCategory.mutate(
          {
            ...vals,
            site: site.key,
            is_published: status.key === publishStatusKeys.PUBLISHED,
          },
          {
            onSuccess: () => {
              toast.success("Category is edited successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in editing Category!");
            },
          }
        );
      } else
        createCategory.mutate(
          {
            ...vals,
            site: site.key,
            is_published: status.key === publishStatusKeys.PUBLISHED,
          },
          {
            onSuccess: () => {
              toast.success("Category is added successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in adding Category!");
            },
          }
        );
    },
    [status, site]
  );

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Category" : "Add Category"}
            height="80vh"
          >
            <Box width="100%" display="flex" columnGap="24px">
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Status</p>
                  <TSelect
                    placeholder=""
                    value={status}
                    loadOptions={() => publishStatuses}
                    defaultOptions={publishStatuses}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setStatus(e)}
                    getOptionLabel={(opt) => opt.value}
                    getOptionValue={(opt) => opt.key}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Site</p>
                  <TSelect
                    placeholder=""
                    value={site}
                    loadOptions={() => siteTypes}
                    defaultOptions={siteTypes}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setSite(e)}
                    getOptionLabel={(opt) => opt.value}
                    getOptionValue={(opt) => opt.key}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>
              </Box>
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Meta title</p>
                  <Controller
                    name="meta_title"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta description</p>
                  <Controller
                    name="meta_description"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        required
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta keywords</p>
                  <Controller
                    name="meta_keywords"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        required
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>SEO text</p>
                  <Controller
                    name="seo_text"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createCategory.isLoading}
            style={{
              width: "120px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default CategoryCreate;
